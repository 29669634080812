<template>
    <v-container class="ml-10" align="center">
         <template>
            <v-sheet  class="mt-5" color="white" align="center" tile>
                <v-row class="fill-height" align="center" justify="center">
                    <v-col cols="12" sm="12">
                        <!--button @click="$router.push('/report')"
                                class="white--text"
                                dark
                                style="background-color: green; padding: 5px; border-radius: 5px; margin-right: 5px; ">
                            General Report
                        </button>
                        <button @click="$router.push('/branch')"
                                class="white--text"
                                dark
                                style="background-color: #077dfa; padding: 5px; border-radius: 5px; margin-right: 5px; ">
                            Edit Profile
                        </button>-->
                        <img class="white--text align-end"
                             src="../../../assets/BuddhaAir1.jpg"
                             style="width: 100%; height: 550px; object-fit: fill;">




                    </v-col>
                </v-row>
            </v-sheet>
        </template>
       <v-sheet color="white" align="center" tile>
           <v-row class="fill-height" align="center" justify="center">
               <v-col cols="12" sm="12">
                   <v-title class="display-1" style="font-weight: bold; color:white; background-color:darkblue;padding:5px; border-radius:10px;">Buddha Airlines</v-title>
                   <br />
                   <p style="font-weight: bold;margin-top:5px">
                       Ring Rd, Kathmandu 44600, Nepal
                   </p>
               </v-col>
           </v-row>
       </v-sheet>
       
        <template>

            <v-row>
                <v-col cols="12" sm="3">
                    <v-card color="#006400" elevation="5" style="height:180px">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Total No. of Flights / Day
                                            </v-list-item-subtitle>
                                            <v-icon color="white" x-large>flight</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <!-- <v-list-item-subtitle class="subtitle-1 white--text" style="font-size:19px"
                                  >742,824</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  class="white--text"
                                  style="font-size: 11px"
                                  >CHANGE TO</v-list-item-subtitle
                                > -->
                                            <v-list-item-subtitle class="white--text"
                                                                  style="font-size: 26px">3200</v-list-item-subtitle>

                                            <v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                            <span class="white--text">3211</span><br />
                                            <span class="white--text">(2.16%)</span>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card color="#FFA500" elevation="5" style="height:180px">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Total No. of Incoming Flights / Day
                                            </v-list-item-subtitle>
                                            <v-icon color="white" x-large>flight_land</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                  >914,175</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  class="white--text"
                                  style="font-size: 11px"
                                  >CHANGE TO</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  class="white--text mb-5"
                                  style="gont-size: 11px"
                                  >PREVIOUSE MONTH</v-list-item-subtitle
                                > -->

                                            <v-list-item-subtitle class="white--text"
                                                                  style="font-size: 26px">1700</v-list-item-subtitle>

                                            <v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="red" class="mt-2 mr-2">fas fa-sort-down</v-icon>
                                            <span class="white--text">1697</span><br />
                                            <span class="white--text">(0.12%)</span>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card color="#077dfa" elevation="5" style="height:180px">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Total No. of Outgoing Flights / Day
                                            </v-list-item-subtitle>
                                            <v-icon color="white" x-large>flight_takeoff</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                  >415,721</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  class="white--text"
                                  style="font-size: 11px"
                                  >CHANGE TO</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  class="white--text mb-5"
                                  style="gont-size: 11px"
                                  >PREVIOUSE MONTH</v-list-item-subtitle
                                > -->
                                            <v-list-item-subtitle class="white--text"
                                                                  style="font-size: 26px">1511</v-list-item-subtitle>

                                            <v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                            <span class="white--text">1514</span><br />
                                            <span class="white--text">(4.16%)</span>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card color="#8B0000" elevation="5" style="height:180px">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Count of Flight Type / Month
                                            </v-list-item-subtitle>
                                            <v-icon color="white" x-large>flight_class</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                  >274,762</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  class="white--text"
                                  style="font-size: 11px"
                                  >CHANGE TO</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  class="white--text mb-5"
                                  style="gont-size: 11px"
                                  >PREVIOUSE MONTH</v-list-item-subtitle
                                > -->
                                            <v-list-item-subtitle class="white--text"
                                                                  style="font-size: 26px">8</v-list-item-subtitle>

                                            <v-list-item-subtitle class="white--text mb-5"
                                                                  style="font-size: 14px">Actual</v-list-item-subtitle>
                                            <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                            <span class="white--text">10</span><br />
                                            <span class="white--text">(2.16%)</span>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                

                <v-col cols="6"
                       v-for="title in titles"
                       v-bind:key="title.link"
                       class="viewCard">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <router-link :to="title.link">
                                <v-card :elevation="10"
                                        class="blue darken-1 text-center"
                                        height="100"
                                        width="100%">
                                    <div>
                                        <v-list-item three-line>

                                            <v-list-item-content>
                                                <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                                <v-list-item-title class="white--text subtitle-1 mt-1 pa-4">
                                                    {{title.name}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="white--text header-6 mt-1">
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                </v-card>
                            </router-link>
                        </template>
                    </v-tooltip>
                </v-col>
            </v-row>
        </template>
       

        <template>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-card class="pa-5" elevation="5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            Performance of Airlines
                        </h1>
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="chartData"
                                        :options="chartOptions"
                                        style="height: 350px" />
                            </div>
                        </template>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-card class="pa-5" elevation="5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            Booking Destination
                        </h1>
                        <template>
                            <div id="chart" ml="10">
                                <apexchart type="pie"
                                           height="350"
                                           width="650"
                                           align="left"
                                           :options="chartsOptions"
                                           :series="series"></apexchart>
                            </div>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template>
            <v-row>
                <!-- <v-col cols="12" sm="6">
                    <v-card color="#232620">
                        <v-app-bar color="rgba(0,0,0,0)" flat>
                            <v-toolbar-title class="white--text title">Domestic Passenger</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-dialog max-width="1000px" v-model="dialogCreate">
                                <template v-slot:activator="{ on }">
                                    <v-chip :success="success"
                                            class="white--text clickbtn ml-3 mb-2 float-right"
                                            color="#077dfa"
                                            dark
                                            v-on="on">
                                        Import Data
                                    </v-chip>
                                    <v-chip :success="success"
                                            class="black--text clickbtn mb-2 float-right"
                                            color="white"
                                            dark
                                            v-on="on">
                                        Add Domestic Passenger
                                    </v-chip>

                                </template>
                                <createDivisionForm :success="success"
                                                    @formResponse="onResponse" />
                            </v-dialog>
                            <v-dialog max-width="1000px" v-model="dialogEdit">
                                <editDivisionForm :serviceID="editedData.serviceID"
                                                  :success="success"
                                                  @formResponse="onResponse"
                                                  v-if="dialogEdit" />
                            </v-dialog>
                            <v-dialog max-width="500px" v-model="dialogDelete">
                                <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
                            </v-dialog>
                        </v-app-bar>
                        <v-data-table :headers="headers" :items="desserts" class="dattab">
                            <template v-slot:item.glutenfree="{ item }">
                                <v-simple-checkbox v-model="item.glutenfree"
                                                   color="cyan"></v-simple-checkbox>
                            </template>
                            <template v-slot:item.actions="">
                                <v-icon @click="editItem(props.row)" class="mr-2" small> {{ svgpencil }}</v-icon>
                                <v-icon @click="deleteItem(props.row)" small> {{ svgdelete }}</v-icon>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col> -->

                <v-col cols="12" sm="6">
                    <v-dialog max-width="1000px" v-model="dialogCreate">
                        <template v-slot:activator="{ on }">
                            <v-btn :success="success"
                                   class=" clickbtn mb-2 float-right"
                                   color="primary"
                                   dark
                                   v-on="on">
                                Add Domestic Passenger
                            </v-btn>
                        </template>
                        <createDivisionForm :success="success" @formResponse="onResponse" />
                    </v-dialog>
                    <v-dialog max-width="1000px" v-model="dialogEdit">
                        <editDivisionForm :serviceID="editedData.serviceID"
                                          :success="success"
                                          @formResponse="onResponse"
                                          v-if="dialogEdit" />
                    </v-dialog>
                    <v-dialog max-width="500px" v-model="dialogDelete">
                        <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
                    </v-dialog>
                    <!--<v-expansion-panels hover>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>Filter</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row align="start">
                                                <v-col class="float-right" cols="12" sm="6">
                                                    <v-select
                                                            :items="province"
                                                            item-text="value"
                                                            item-value="id"
                                                            label="Select Province"
                                                            v-model="editedData.provinceId"
                                                    ></v-select>
                                                </v-col>
                                                <v-col class="float-right" cols="12" sm="6">
                                                    <v-text-field label="Seartch input" v-model="serverParams.search"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" justify="center">
                                                <v-btn @click="searchInput" color="primary" dark>
                                                    <i class="material-icons">search</i>Search
                                                </v-btn>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>-->

                    <vue-good-table :columns="columns"
                                    :isLoading.sync="isLoading"
                                    :pagination-options="{ enabled: true,perPageDropdown: [10, 20,30]  }"
                                    :rows="rows"
                                    :totalRows="totalRecords"
                                    @on-column-filter="onColumnFilter"
                                    @on-page-change="onPageChange"
                                    @on-per-page-change="onPerPageChange"
                                    @on-sort-change="onSortChange"
                                    mode="remote">
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'actions'">
                                <v-icon @click="editItem(props.row)" class="mr-2" small>edit</v-icon>
                                <v-icon @click="deleteItem(props.row)" small>delete</v-icon>
                            </span>
                        </template>
                    </vue-good-table>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-card class="pa-5" elevation="5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            Monthly Performance of Airlines
                        </h1>
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="monthlyData"
                                        :options="chartOptions"
                                        style="height: 350px" />
                            </div>
                        </template>
                    </v-card>

                </v-col>
            </v-row>

        </template>
    </v-container>
</template>
<script>
    import { mdiPencil, mdiDelete } from "@mdi/js";
    import axios from "axios";
    import { parseJwt } from "@/mixins/parseJwt";
    import createDivisionForm from "../DomesticPassenger/create.vue";
    import deleteListData from "../../../components/deleteModal.vue";
    import editDivisionForm from "../DomesticPassenger/edit.vue"


    export default {
        name: "BuddhaAir",
        components: {
            createDivisionForm,
            editDivisionForm,
            deleteListData
            
        },
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {

                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                success: false,
         
                dialogEdit: false,
                dialogDelete: false,
                items: ["Foo", "Bar", "Fizz", "Buzz"],
                province: [],
                deleteFormData: {},
                popout: true,
                titles: [
                    {
                        name: "Generate Report",
                        link: "/report",
                    },
                    {
                        name: "Profile",
                        link: "/branch",
                    },
                ],
                svgpencil: mdiPencil,
                svgdelete: mdiDelete,
                dialogCreate: false,
                slides: ["", "", "", ""],
                chartData: [
                    ["Destinations", "Incoming", "Outgoing"],
                    ["Ktm to Mt", 15000, 12000],
                    ["Ktm to Brng", 14230, 12000],
                    ["Ktm to Bharatpur", 13660, 15000],
                    ["Biratnagar to Ktm", 7030, 9020],
                    ["Bharatpur to Ktm", 12025, 11020],
                    ["Ktm to Bhadrapur", 3040, 3504],
                    ["Bhadrapur to Ktm", 2100, 1420],
                    ["Ktm to Bhairahawa", 10000, 12300],
                ],
                series: [
                    56090, 44961, 30200, 44336, 20350, 56210, 44219, 30212, 44320, 20120, 1200, 2130, 1239, 1236, 2810
                ],
                monthlyData: [
                    ["Month", "Passenger"],
                    ["Jan", 2000],
                    ["Feb", 2170],
                    ["Mar", 2060],
                    ["Apr", 3030],
                    ["May", 40250],
                    ["Jun", 40400],
                    ["Jul", 50000],
                    ["Aug", 58000],
                    ["Sep", 68000],
                    ["Oct", 7000],
                    ["Nov", 58000],
                    ["Dec", 68000],
                ],
                chartOptions: {
                    chart: {
                        title: "Company Performance",
                        subtitle: "Sales, Expenses, and Profit: 2014-2020",
                    },
                },
                chartsOptions: {
                    chart: {
                        width: 800,
                        type: "donut",
                    },
                    labels: [
                        "Pokhara",
                        "Kathmandu",
                        "Rajbiraj",
                        "Bhairahawa",
                        "Biratnagar",
                        "Bharatpur",
                        "Bhadrapur",
                        "Dhangadhi",
                        "Tumlingtar",
                        "Nepalgunj",
                        "Surkhet",
                        "Janakpur",
                        "Simara",
                        "Varanasi"
                    ],
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 500,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
                columns: [
                    {
                        label: "S No.",
                        field: "countIndex"
                    },
                    {
                        label: "Flight Type",
                        field: "airlineName"
                    },
                    {
                        label: "Arrival Departure",
                        field: "arrivalDeparture"
                    },
                    {
                        label: "Number Of Person",
                        field: "numberOfPerson"
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        sortable: false
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    serviceName: "",
                    serviceDescription: ""
                },
                desserts: [
                    {
                        sno: 1,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 60,
                        departureTime: "08:30 A.M",
                        arrivalTime: "10:00 A.M"
                    },
                    {
                        sno: 2,
                        airportName: "Yeti Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 40,
                        departureTime: "02:15 P.M",
                        arrivalTime: "3:45 P.M"
                    },
                    {
                        sno: 3,
                        airportName: "Guna Airlines",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 20,
                        departureTime: "04:45 P.M",
                        arrivalTime: "06:25 P.M"
                    },
                    {
                        sno: 4,
                        airportName: "Saurya Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 15,
                        departureTime: "06:25 P.M",
                        arrivalTime: "08:05 P.M"
                    },
                    {
                        sno: 5,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 20,
                        departureTime: "07:00 P.M",
                        arrivalTime: "08:30 P.M"
                    },
                ],
                headers: [
                    { text: "S.No", value: "sno" },
                    {
                        text: "Airport Name",
                        align: "start",
                        sortable: false,
                        value: "airportName",
                    },
                    { text: "Arrival Departure", value: "arrivalDeparture" },
                    { text: "Number of Person", value: "numberOfPerson" },
                    { text: "Departure Time", value: "departureTime" },
                    { text: "Arrival Time", value: "arrivalTime" },
                    { text: "Actions", value: "actions", sortable: false },
                ],

            };

        },
        mounted() {
            this.loadItems();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.serviceID = props.id;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Airport/DeleteDomesticPassengerByIDAsync/" + props.id;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                };
                axios.post("Airport/GetDomesticPassengerDetailListAsync", param).then(response => {
                    this.rows = response.data.data;
                    console.log('this.rows',this.rows)
                    this.totalRecords = response.data.totalCount;
                })
                    .catch(err => {
                        console.log(err)
                    });

            }
        }
    };
</script>
